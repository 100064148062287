export interface Option {
  value: string;
  label: React.ReactNode;
  children?: Option[];
}

export const downloadOptions: Option[] = [
  {
    value: "download",
    label: "Download",
    children: [
      {
        value: "zip",
        label: ".zip",
      },
      {
        value: "tar",
        label: ".tar",
      },
      {
        value: "tar.gz",
        label: ".tar.gz",
      },
    ],
  },
];

export const options: Option[] = [
  {
    value: "rename",
    label: "Rename",
  },
  {
    value: "download",
    label: "Download",
    children: [
      {
        value: "zip",
        label: ".zip",
      },
      {
        value: "tar",
        label: ".tar",
      },
      {
        value: "tar.gz",
        label: ".tar.gz",
      },
    ],
  },
  {
    value: "delete",
    label: <span className="red-text">Delete</span>,
  },
];
export const individualUserOptions: Option[] = [
  {
    value: "rename",
    label: "Rename",
  },
  {
    value: "delete",
    label: <span className="red-text">Delete</span>,
  },
];
