import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotification, INotificationItem } from "../../interface/Notification";

export const intialNotificationItem = {
  id: "",
  title: "",
  description: "",
  added_datetime: "",
  is_read: false,
};

const initialState: INotification = {
  unreadCount: 0,
  newNotifications: intialNotificationItem,
  notifications: [],
  page: 1,
  isAllRead: false,
  has_all_loaded: false,
  notificationLoader: true,
  connectionError: false,
};

const NotificationSlice = createSlice({
  name: "notification_reducer",
  initialState: initialState,
  reducers: {
    updateUnreadNotificationCount: (state, action: PayloadAction<number>) => {
      state.unreadCount = action.payload;
    },
    setNewNotification: (state, action: PayloadAction<INotificationItem>) => {
      state.newNotifications = action.payload;
    },
    updateNotificationList: (
      state,
      action: PayloadAction<INotificationItem>
    ) => {
      state.notifications.unshift(action.payload);
    },
    loadNotifications: (state, action: PayloadAction<INotificationItem[]>) => {
      state.notifications = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateIsAllRead: (state, action: PayloadAction<boolean>) => {
      state.isAllRead = action.payload;
    },
    updateHasAllLoaded: (state, action: PayloadAction<boolean>) => {
      state.has_all_loaded = action.payload;
    },
    setNotifcationLoader: (state, action: PayloadAction<boolean>) => {
      state.notificationLoader = action.payload;
    },
    setConnectionErr: (state, action: PayloadAction<boolean>) => {
      state.connectionError = action.payload;
    },
    resetNotificationReducer: () => initialState,
  },
});
export default NotificationSlice.reducer;
export const {
  updateUnreadNotificationCount,
  setNewNotification,
  loadNotifications,
  updatePage,
  updateIsAllRead,
  updateHasAllLoaded,
  updateNotificationList,
  setNotifcationLoader,
  setConnectionErr,
  resetNotificationReducer,
} = NotificationSlice.actions;
